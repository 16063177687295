@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package showcaseapp.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val ic_alist: DrawableResource by 
      lazy { init_ic_alist() }

  public val ic_dropbox: DrawableResource by 
      lazy { init_ic_dropbox() }

  public val ic_ftp: DrawableResource by 
      lazy { init_ic_ftp() }

  public val ic_github: DrawableResource by 
      lazy { init_ic_github() }

  public val ic_google_drive: DrawableResource by 
      lazy { init_ic_google_drive() }

  public val ic_google_photos: DrawableResource by 
      lazy { init_ic_google_photos() }

  public val ic_info: DrawableResource by 
      lazy { init_ic_info() }

  public val ic_launcher_background: DrawableResource by 
      lazy { init_ic_launcher_background() }

  public val ic_launcher_main: DrawableResource by 
      lazy { init_ic_launcher_main() }

  public val ic_launcher_tv: DrawableResource by 
      lazy { init_ic_launcher_tv() }

  public val ic_local: DrawableResource by 
      lazy { init_ic_local() }

  public val ic_onedrive: DrawableResource by 
      lazy { init_ic_onedrive() }

  public val ic_pexels: DrawableResource by 
      lazy { init_ic_pexels() }

  public val ic_smb: DrawableResource by 
      lazy { init_ic_smb() }

  public val ic_telegram_app: DrawableResource by 
      lazy { init_ic_telegram_app() }

  public val ic_terminal: DrawableResource by 
      lazy { init_ic_terminal() }

  public val ic_tmdb: DrawableResource by 
      lazy { init_ic_tmdb() }

  public val ic_union: DrawableResource by 
      lazy { init_ic_union() }

  public val ic_unsplash: DrawableResource by 
      lazy { init_ic_unsplash() }

  public val ic_unsplash_symbol: DrawableResource by 
      lazy { init_ic_unsplash_symbol() }

  public val ic_webdav: DrawableResource by 
      lazy { init_ic_webdav() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("ic_alist", CommonMainDrawable0.ic_alist)
  map.put("ic_dropbox", CommonMainDrawable0.ic_dropbox)
  map.put("ic_ftp", CommonMainDrawable0.ic_ftp)
  map.put("ic_github", CommonMainDrawable0.ic_github)
  map.put("ic_google_drive", CommonMainDrawable0.ic_google_drive)
  map.put("ic_google_photos", CommonMainDrawable0.ic_google_photos)
  map.put("ic_info", CommonMainDrawable0.ic_info)
  map.put("ic_launcher_background", CommonMainDrawable0.ic_launcher_background)
  map.put("ic_launcher_main", CommonMainDrawable0.ic_launcher_main)
  map.put("ic_launcher_tv", CommonMainDrawable0.ic_launcher_tv)
  map.put("ic_local", CommonMainDrawable0.ic_local)
  map.put("ic_onedrive", CommonMainDrawable0.ic_onedrive)
  map.put("ic_pexels", CommonMainDrawable0.ic_pexels)
  map.put("ic_smb", CommonMainDrawable0.ic_smb)
  map.put("ic_telegram_app", CommonMainDrawable0.ic_telegram_app)
  map.put("ic_terminal", CommonMainDrawable0.ic_terminal)
  map.put("ic_tmdb", CommonMainDrawable0.ic_tmdb)
  map.put("ic_union", CommonMainDrawable0.ic_union)
  map.put("ic_unsplash", CommonMainDrawable0.ic_unsplash)
  map.put("ic_unsplash_symbol", CommonMainDrawable0.ic_unsplash_symbol)
  map.put("ic_webdav", CommonMainDrawable0.ic_webdav)
}

internal val Res.drawable.ic_alist: DrawableResource
  get() = CommonMainDrawable0.ic_alist

private fun init_ic_alist(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_alist",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_alist.xml", -1, -1),
    )
)

internal val Res.drawable.ic_dropbox: DrawableResource
  get() = CommonMainDrawable0.ic_dropbox

private fun init_ic_dropbox(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_dropbox",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_dropbox.xml", -1, -1),
    )
)

internal val Res.drawable.ic_ftp: DrawableResource
  get() = CommonMainDrawable0.ic_ftp

private fun init_ic_ftp(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_ftp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_ftp.xml", -1, -1),
    )
)

internal val Res.drawable.ic_github: DrawableResource
  get() = CommonMainDrawable0.ic_github

private fun init_ic_github(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_github",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_github.xml", -1, -1),
    )
)

internal val Res.drawable.ic_google_drive: DrawableResource
  get() = CommonMainDrawable0.ic_google_drive

private fun init_ic_google_drive(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_google_drive",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_google_drive.xml", -1, -1),
    )
)

internal val Res.drawable.ic_google_photos: DrawableResource
  get() = CommonMainDrawable0.ic_google_photos

private fun init_ic_google_photos(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_google_photos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_google_photos.xml", -1, -1),
    )
)

internal val Res.drawable.ic_info: DrawableResource
  get() = CommonMainDrawable0.ic_info

private fun init_ic_info(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_info",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_info.xml", -1, -1),
    )
)

internal val Res.drawable.ic_launcher_background: DrawableResource
  get() = CommonMainDrawable0.ic_launcher_background

private fun init_ic_launcher_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_launcher_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_launcher_background.xml", -1, -1),
    )
)

internal val Res.drawable.ic_launcher_main: DrawableResource
  get() = CommonMainDrawable0.ic_launcher_main

private fun init_ic_launcher_main(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_launcher_main",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_launcher_main.xml", -1, -1),
    )
)

internal val Res.drawable.ic_launcher_tv: DrawableResource
  get() = CommonMainDrawable0.ic_launcher_tv

private fun init_ic_launcher_tv(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_launcher_tv",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_launcher_tv.xml", -1, -1),
    )
)

internal val Res.drawable.ic_local: DrawableResource
  get() = CommonMainDrawable0.ic_local

private fun init_ic_local(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_local",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_local.xml", -1, -1),
    )
)

internal val Res.drawable.ic_onedrive: DrawableResource
  get() = CommonMainDrawable0.ic_onedrive

private fun init_ic_onedrive(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_onedrive",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_onedrive.xml", -1, -1),
    )
)

internal val Res.drawable.ic_pexels: DrawableResource
  get() = CommonMainDrawable0.ic_pexels

private fun init_ic_pexels(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_pexels",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_pexels.xml", -1, -1),
    )
)

internal val Res.drawable.ic_smb: DrawableResource
  get() = CommonMainDrawable0.ic_smb

private fun init_ic_smb(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_smb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_smb.xml", -1, -1),
    )
)

internal val Res.drawable.ic_telegram_app: DrawableResource
  get() = CommonMainDrawable0.ic_telegram_app

private fun init_ic_telegram_app(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_telegram_app",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_telegram_app.xml", -1, -1),
    )
)

internal val Res.drawable.ic_terminal: DrawableResource
  get() = CommonMainDrawable0.ic_terminal

private fun init_ic_terminal(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_terminal",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_terminal.xml", -1, -1),
    )
)

internal val Res.drawable.ic_tmdb: DrawableResource
  get() = CommonMainDrawable0.ic_tmdb

private fun init_ic_tmdb(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_tmdb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_tmdb.xml", -1, -1),
    )
)

internal val Res.drawable.ic_union: DrawableResource
  get() = CommonMainDrawable0.ic_union

private fun init_ic_union(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_union",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_union.xml", -1, -1),
    )
)

internal val Res.drawable.ic_unsplash: DrawableResource
  get() = CommonMainDrawable0.ic_unsplash

private fun init_ic_unsplash(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_unsplash",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_unsplash.xml", -1, -1),
    )
)

internal val Res.drawable.ic_unsplash_symbol: DrawableResource
  get() = CommonMainDrawable0.ic_unsplash_symbol

private fun init_ic_unsplash_symbol(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_unsplash_symbol",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_unsplash_symbol.xml", -1, -1),
    )
)

internal val Res.drawable.ic_webdav: DrawableResource
  get() = CommonMainDrawable0.ic_webdav

private fun init_ic_webdav(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_webdav",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/drawable/ic_webdav.xml", -1, -1),
    )
)
