@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package showcaseapp.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val Url: StringResource by 
      lazy { init_Url() }

  public val _off: StringResource by 
      lazy { init__off() }

  public val _on: StringResource by 
      lazy { init__on() }

  public val about: StringResource by 
      lazy { init_about() }

  public val ad_mob_banner_id: StringResource by 
      lazy { init_ad_mob_banner_id() }

  public val ad_mob_list_item_native_id: StringResource by 
      lazy { init_ad_mob_list_item_native_id() }

  public val ad_mob_main_page_native_id: StringResource by 
      lazy { init_ad_mob_main_page_native_id() }

  public val ad_mob_setting_page_native_id: StringResource by 
      lazy { init_ad_mob_setting_page_native_id() }

  public val ad_mob_splash_id: StringResource by 
      lazy { init_ad_mob_splash_id() }

  public val ad_mob_test_banner_id: StringResource by 
      lazy { init_ad_mob_test_banner_id() }

  public val ad_mob_test_list_item_native_1_id: StringResource by 
      lazy { init_ad_mob_test_list_item_native_1_id() }

  public val ad_mob_test_list_item_native_id: StringResource by 
      lazy { init_ad_mob_test_list_item_native_id() }

  public val ad_mob_test_splash_id: StringResource by 
      lazy { init_ad_mob_test_splash_id() }

  public val add: StringResource by 
      lazy { init_add() }

  public val addSource: StringResource by 
      lazy { init_addSource() }

  public val add_success: StringResource by 
      lazy { init_add_success() }

  public val ads: StringResource by 
      lazy { init_ads() }

  public val ads_loading: StringResource by 
      lazy { init_ads_loading() }

  public val anonymous_usage: StringResource by 
      lazy { init_anonymous_usage() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val app_update_failed: StringResource by 
      lazy { init_app_update_failed() }

  public val are_you_clear_cache: StringResource by 
      lazy { init_are_you_clear_cache() }

  public val auto_open_latest_source: StringResource by 
      lazy { init_auto_open_latest_source() }

  public val auto_play: StringResource by 
      lazy { init_auto_play() }

  public val auto_refresh_source_content: StringResource by 
      lazy { init_auto_refresh_source_content() }

  public val auto_update: StringResource by 
      lazy { init_auto_update() }

  public val backward: StringResource by 
      lazy { init_backward() }

  public val branch_name_is_invalid: StringResource by 
      lazy { init_branch_name_is_invalid() }

  public val branche_name: StringResource by 
      lazy { init_branche_name() }

  public val button_back: StringResource by 
      lazy { init_button_back() }

  public val cache_size: StringResource by 
      lazy { init_cache_size() }

  public val cancel: StringResource by 
      lazy { init_cancel() }

  public val check_for_update: StringResource by 
      lazy { init_check_for_update() }

  public val choose_image_type: StringResource by 
      lazy { init_choose_image_type() }

  public val choose_language: StringResource by 
      lazy { init_choose_language() }

  public val choose_region: StringResource by 
      lazy { init_choose_region() }

  public val choose_type: StringResource by 
      lazy { init_choose_type() }

  public val close: StringResource by 
      lazy { init_close() }

  public val collection_id: StringResource by 
      lazy { init_collection_id() }

  public val column: StringResource by 
      lazy { init_column() }

  public val coming_soon: StringResource by 
      lazy { init_coming_soon() }

  public val confirm: StringResource by 
      lazy { init_confirm() }

  public val confirm_delete_text: StringResource by 
      lazy { init_confirm_delete_text() }

  public val connection_failed: StringResource by 
      lazy { init_connection_failed() }

  public val connection_successful: StringResource by 
      lazy { init_connection_successful() }

  public val connection_tiemout: StringResource by 
      lazy { init_connection_tiemout() }

  public val contain_video: StringResource by 
      lazy { init_contain_video() }

  public val current_version: StringResource by 
      lazy { init_current_version() }

  public val dark_mode: StringResource by 
      lazy { init_dark_mode() }

  public val data_not_found: StringResource by 
      lazy { init_data_not_found() }

  public val data_under_construct: StringResource by 
      lazy { init_data_under_construct() }

  public val default_branch: StringResource by 
      lazy { init_default_branch() }

  public val delete: StringResource by 
      lazy { init_delete() }

  public val device_brand: StringResource by 
      lazy { init_device_brand() }

  public val device_model: StringResource by 
      lazy { init_device_model() }

  public val device_version: StringResource by 
      lazy { init_device_version() }

  public val display_mode: StringResource by 
      lazy { init_display_mode() }

  public val display_mode_center: StringResource by 
      lazy { init_display_mode_center() }

  public val display_mode_full: StringResource by 
      lazy { init_display_mode_full() }

  public val display_mode_full_screen: StringResource by 
      lazy { init_display_mode_full_screen() }

  public val display_orientation_horizontal: StringResource by 
      lazy { init_display_orientation_horizontal() }

  public val display_orientation_vertical: StringResource by 
      lazy { init_display_orientation_vertical() }

  public val display_style_bento: StringResource by 
      lazy { init_display_style_bento() }

  public val display_style_calender: StringResource by 
      lazy { init_display_style_calender() }

  public val display_style_carousel: StringResource by 
      lazy { init_display_style_carousel() }

  public val display_style_cube: StringResource by 
      lazy { init_display_style_cube() }

  public val display_style_fade: StringResource by 
      lazy { init_display_style_fade() }

  public val display_style_frame_wall: StringResource by 
      lazy { init_display_style_frame_wall() }

  public val display_style_reveal: StringResource by 
      lazy { init_display_style_reveal() }

  public val display_style_slide: StringResource by 
      lazy { init_display_style_slide() }

  public val donate: StringResource by 
      lazy { init_donate() }

  public val dream_description: StringResource by 
      lazy { init_dream_description() }

  public val dream_label: StringResource by 
      lazy { init_dream_label() }

  public val edit: StringResource by 
      lazy { init_edit() }

  public val error: StringResource by 
      lazy { init_error() }

  public val feedback: StringResource by 
      lazy { init_feedback() }

  public val follow_system: StringResource by 
      lazy { init_follow_system() }

  public val forward: StringResource by 
      lazy { init_forward() }

  public val frame_style: StringResource by 
      lazy { init_frame_style() }

  public val frame_wall_fix_size: StringResource by 
      lazy { init_frame_wall_fix_size() }

  public val frame_wall_random_size: StringResource by 
      lazy { init_frame_wall_random_size() }

  public val github_access_token_tips_title: StringResource by 
      lazy { init_github_access_token_tips_title() }

  public val github_acess_token_tips_1: StringResource by 
      lazy { init_github_acess_token_tips_1() }

  public val github_acess_token_tips_2: StringResource by 
      lazy { init_github_acess_token_tips_2() }

  public val github_acess_token_tips_3: StringResource by 
      lazy { init_github_acess_token_tips_3() }

  public val github_acess_token_tips_4: StringResource by 
      lazy { init_github_acess_token_tips_4() }

  public val github_acess_token_tips_5: StringResource by 
      lazy { init_github_acess_token_tips_5() }

  public val have_no_permission: StringResource by 
      lazy { init_have_no_permission() }

  public val hide_password: StringResource by 
      lazy { init_hide_password() }

  public val home: StringResource by 
      lazy { init_home() }

  public val host: StringResource by 
      lazy { init_host() }

  public val host_is_invalid: StringResource by 
      lazy { init_host_is_invalid() }

  public val interval: StringResource by 
      lazy { init_interval() }

  public val interval_time_unit: StringResource by 
      lazy { init_interval_time_unit() }

  public val ip_is_invalid: StringResource by 
      lazy { init_ip_is_invalid() }

  public val its_in_beta_test: StringResource by 
      lazy { init_its_in_beta_test() }

  public val la_ar: StringResource by 
      lazy { init_la_ar() }

  public val la_az: StringResource by 
      lazy { init_la_az() }

  public val la_be: StringResource by 
      lazy { init_la_be() }

  public val la_da: StringResource by 
      lazy { init_la_da() }

  public val la_de: StringResource by 
      lazy { init_la_de() }

  public val la_en_US: StringResource by 
      lazy { init_la_en_US() }

  public val la_es: StringResource by 
      lazy { init_la_es() }

  public val la_fa: StringResource by 
      lazy { init_la_fa() }

  public val la_fil: StringResource by 
      lazy { init_la_fil() }

  public val la_fr: StringResource by 
      lazy { init_la_fr() }

  public val la_hu: StringResource by 
      lazy { init_la_hu() }

  public val la_in: StringResource by 
      lazy { init_la_in() }

  public val la_it: StringResource by 
      lazy { init_la_it() }

  public val la_ja: StringResource by 
      lazy { init_la_ja() }

  public val la_ko: StringResource by 
      lazy { init_la_ko() }

  public val la_ms: StringResource by 
      lazy { init_la_ms() }

  public val la_nl: StringResource by 
      lazy { init_la_nl() }

  public val la_pl: StringResource by 
      lazy { init_la_pl() }

  public val la_pt_BR: StringResource by 
      lazy { init_la_pt_BR() }

  public val la_ru: StringResource by 
      lazy { init_la_ru() }

  public val la_sr: StringResource by 
      lazy { init_la_sr() }

  public val la_tr: StringResource by 
      lazy { init_la_tr() }

  public val la_uk: StringResource by 
      lazy { init_la_uk() }

  public val la_vi: StringResource by 
      lazy { init_la_vi() }

  public val la_zh_CN: StringResource by 
      lazy { init_la_zh_CN() }

  public val la_zh_TW: StringResource by 
      lazy { init_la_zh_TW() }

  public val language: StringResource by 
      lazy { init_language() }

  public val language_of_film_content: StringResource by 
      lazy { init_language_of_film_content() }

  public val leave_blank_is_default_branch: StringResource by 
      lazy { init_leave_blank_is_default_branch() }

  public val link: StringResource by 
      lazy { init_link() }

  public val link_to: StringResource by 
      lazy { init_link_to() }

  public val loading: StringResource by 
      lazy { init_loading() }

  public val local_source_name: StringResource by 
      lazy { init_local_source_name() }

  public val matrix_size: StringResource by 
      lazy { init_matrix_size() }

  public val membership: StringResource by 
      lazy { init_membership() }

  public val minutes: StringResource by 
      lazy { init_minutes() }

  public val my: StringResource by 
      lazy { init_my() }

  public val name_is_invalid: StringResource by 
      lazy { init_name_is_invalid() }

  public val name_require_hint: StringResource by 
      lazy { init_name_require_hint() }

  public val notification_channel_id: StringResource by 
      lazy { init_notification_channel_id() }

  public val notification_title: StringResource by 
      lazy { init_notification_title() }

  public val open_source_license: StringResource by 
      lazy { init_open_source_license() }

  public val orientation: StringResource by 
      lazy { init_orientation() }

  public val password: StringResource by 
      lazy { init_password() }

  public val path: StringResource by 
      lazy { init_path() }

  public val path_is_invalid: StringResource by 
      lazy { init_path_is_invalid() }

  public val play: StringResource by 
      lazy { init_play() }

  public val port: StringResource by 
      lazy { init_port() }

  public val port_is_invalid: StringResource by 
      lazy { init_port_is_invalid() }

  public val privacy_policy: StringResource by 
      lazy { init_privacy_policy() }

  public val rate: StringResource by 
      lazy { init_rate() }

  public val recursive_source_folder: StringResource by 
      lazy { init_recursive_source_folder() }

  public val repo_name: StringResource by 
      lazy { init_repo_name() }

  public val repo_owner: StringResource by 
      lazy { init_repo_owner() }

  public val repo_url_require_hint: StringResource by 
      lazy { init_repo_url_require_hint() }

  public val request_permission: StringResource by 
      lazy { init_request_permission() }

  public val row: StringResource by 
      lazy { init_row() }

  public val save: StringResource by 
      lazy { init_save() }

  public val save_success: StringResource by 
      lazy { init_save_success() }

  public val second: StringResource by 
      lazy { init_second() }

  public val settings: StringResource by 
      lazy { init_settings() }

  public val share: StringResource by 
      lazy { init_share() }

  public val share_to_others: StringResource by 
      lazy { init_share_to_others() }

  public val show_content_meta_info: StringResource by 
      lazy { init_show_content_meta_info() }

  public val show_movie_from_selected_region: StringResource by 
      lazy { init_show_movie_from_selected_region() }

  public val show_password: StringResource by 
      lazy { init_show_password() }

  public val show_time_and_date: StringResource by 
      lazy { init_show_time_and_date() }

  public val show_time_progress_indicator: StringResource by 
      lazy { init_show_time_progress_indicator() }

  public val showcase_about: StringResource by 
      lazy { init_showcase_about() }

  public val showcase_general: StringResource by 
      lazy { init_showcase_general() }

  public val showcase_preview: StringResource by 
      lazy { init_showcase_preview() }

  public val showcase_source_settings: StringResource by 
      lazy { init_showcase_source_settings() }

  public val showcase_style: StringResource by 
      lazy { init_showcase_style() }

  public val sort_rule: StringResource by 
      lazy { init_sort_rule() }

  public val sort_rule_date_asc: StringResource by 
      lazy { init_sort_rule_date_asc() }

  public val sort_rule_date_desc: StringResource by 
      lazy { init_sort_rule_date_desc() }

  public val sort_rule_name_asc: StringResource by 
      lazy { init_sort_rule_name_asc() }

  public val sort_rule_name_desc: StringResource by 
      lazy { init_sort_rule_name_desc() }

  public val sort_rule_random: StringResource by 
      lazy { init_sort_rule_random() }

  public val source: StringResource by 
      lazy { init_source() }

  public val source_name: StringResource by 
      lazy { init_source_name() }

  public val source_name_already_exists: StringResource by 
      lazy { init_source_name_already_exists() }

  public val source_preference: StringResource by 
      lazy { init_source_preference() }

  public val sources: StringResource by 
      lazy { init_sources() }

  public val sub_folder_hint: StringResource by 
      lazy { init_sub_folder_hint() }

  public val system: StringResource by 
      lazy { init_system() }

  public val system_default: StringResource by 
      lazy { init_system_default() }

  public val system_screensaver: StringResource by 
      lazy { init_system_screensaver() }

  public val telegram_channel: StringResource by 
      lazy { init_telegram_channel() }

  public val test_connection: StringResource by 
      lazy { init_test_connection() }

  public val thanks: StringResource by 
      lazy { init_thanks() }

  public val the_number_of_files_may_be_too_large_please_wait: StringResource by 
      lazy { init_the_number_of_files_may_be_too_large_please_wait() }

  public val time_unit_minute: StringResource by 
      lazy { init_time_unit_minute() }

  public val time_unit_second: StringResource by 
      lazy { init_time_unit_second() }

  public val tmdb_au: StringResource by 
      lazy { init_tmdb_au() }

  public val tmdb_ca: StringResource by 
      lazy { init_tmdb_ca() }

  public val tmdb_cn: StringResource by 
      lazy { init_tmdb_cn() }

  public val tmdb_de: StringResource by 
      lazy { init_tmdb_de() }

  public val tmdb_es: StringResource by 
      lazy { init_tmdb_es() }

  public val tmdb_fr: StringResource by 
      lazy { init_tmdb_fr() }

  public val tmdb_image_type_backdrop: StringResource by 
      lazy { init_tmdb_image_type_backdrop() }

  public val tmdb_in: StringResource by 
      lazy { init_tmdb_in() }

  public val tmdb_it: StringResource by 
      lazy { init_tmdb_it() }

  public val tmdb_jp: StringResource by 
      lazy { init_tmdb_jp() }

  public val tmdb_kr: StringResource by 
      lazy { init_tmdb_kr() }

  public val tmdb_lang_de_de: StringResource by 
      lazy { init_tmdb_lang_de_de() }

  public val tmdb_lang_en_us: StringResource by 
      lazy { init_tmdb_lang_en_us() }

  public val tmdb_lang_es_es: StringResource by 
      lazy { init_tmdb_lang_es_es() }

  public val tmdb_lang_fr_fr: StringResource by 
      lazy { init_tmdb_lang_fr_fr() }

  public val tmdb_lang_it_it: StringResource by 
      lazy { init_tmdb_lang_it_it() }

  public val tmdb_lang_ja_jp: StringResource by 
      lazy { init_tmdb_lang_ja_jp() }

  public val tmdb_lang_ko_kr: StringResource by 
      lazy { init_tmdb_lang_ko_kr() }

  public val tmdb_lang_ru_ru: StringResource by 
      lazy { init_tmdb_lang_ru_ru() }

  public val tmdb_lang_type_poster: StringResource by 
      lazy { init_tmdb_lang_type_poster() }

  public val tmdb_lang_zh_cn: StringResource by 
      lazy { init_tmdb_lang_zh_cn() }

  public val tmdb_lang_zh_tw: StringResource by 
      lazy { init_tmdb_lang_zh_tw() }

  public val tmdb_now_playing: StringResource by 
      lazy { init_tmdb_now_playing() }

  public val tmdb_popular: StringResource by 
      lazy { init_tmdb_popular() }

  public val tmdb_ru: StringResource by 
      lazy { init_tmdb_ru() }

  public val tmdb_top_rated: StringResource by 
      lazy { init_tmdb_top_rated() }

  public val tmdb_uk: StringResource by 
      lazy { init_tmdb_uk() }

  public val tmdb_upcoming: StringResource by 
      lazy { init_tmdb_upcoming() }

  public val tmdb_us: StringResource by 
      lazy { init_tmdb_us() }

  public val topic_id_or_slug: StringResource by 
      lazy { init_topic_id_or_slug() }

  public val under_construction: StringResource by 
      lazy { init_under_construction() }

  public val unknown: StringResource by 
      lazy { init_unknown() }

  public val unsplash_collections_photos: StringResource by 
      lazy { init_unsplash_collections_photos() }

  public val unsplash_feed_photos: StringResource by 
      lazy { init_unsplash_feed_photos() }

  public val unsplash_topics_photos: StringResource by 
      lazy { init_unsplash_topics_photos() }

  public val unsplash_users_collections: StringResource by 
      lazy { init_unsplash_users_collections() }

  public val unsplash_users_likes: StringResource by 
      lazy { init_unsplash_users_likes() }

  public val unsplash_users_photos: StringResource by 
      lazy { init_unsplash_users_photos() }

  public val unsupport_type: StringResource by 
      lazy { init_unsupport_type() }

  public val unsupported_type: StringResource by 
      lazy { init_unsupported_type() }

  public val up_to_date: StringResource by 
      lazy { init_up_to_date() }

  public val update: StringResource by 
      lazy { init_update() }

  public val url_is_invalid: StringResource by 
      lazy { init_url_is_invalid() }

  public val user: StringResource by 
      lazy { init_user() }

  public val userName: StringResource by 
      lazy { init_userName() }

  public val welcome: StringResource by 
      lazy { init_welcome() }

  public val zoom_effect: StringResource by 
      lazy { init_zoom_effect() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("Url", CommonMainString0.Url)
  map.put("_off", CommonMainString0._off)
  map.put("_on", CommonMainString0._on)
  map.put("about", CommonMainString0.about)
  map.put("ad_mob_banner_id", CommonMainString0.ad_mob_banner_id)
  map.put("ad_mob_list_item_native_id", CommonMainString0.ad_mob_list_item_native_id)
  map.put("ad_mob_main_page_native_id", CommonMainString0.ad_mob_main_page_native_id)
  map.put("ad_mob_setting_page_native_id", CommonMainString0.ad_mob_setting_page_native_id)
  map.put("ad_mob_splash_id", CommonMainString0.ad_mob_splash_id)
  map.put("ad_mob_test_banner_id", CommonMainString0.ad_mob_test_banner_id)
  map.put("ad_mob_test_list_item_native_1_id", CommonMainString0.ad_mob_test_list_item_native_1_id)
  map.put("ad_mob_test_list_item_native_id", CommonMainString0.ad_mob_test_list_item_native_id)
  map.put("ad_mob_test_splash_id", CommonMainString0.ad_mob_test_splash_id)
  map.put("add", CommonMainString0.add)
  map.put("addSource", CommonMainString0.addSource)
  map.put("add_success", CommonMainString0.add_success)
  map.put("ads", CommonMainString0.ads)
  map.put("ads_loading", CommonMainString0.ads_loading)
  map.put("anonymous_usage", CommonMainString0.anonymous_usage)
  map.put("app_name", CommonMainString0.app_name)
  map.put("app_update_failed", CommonMainString0.app_update_failed)
  map.put("are_you_clear_cache", CommonMainString0.are_you_clear_cache)
  map.put("auto_open_latest_source", CommonMainString0.auto_open_latest_source)
  map.put("auto_play", CommonMainString0.auto_play)
  map.put("auto_refresh_source_content", CommonMainString0.auto_refresh_source_content)
  map.put("auto_update", CommonMainString0.auto_update)
  map.put("backward", CommonMainString0.backward)
  map.put("branch_name_is_invalid", CommonMainString0.branch_name_is_invalid)
  map.put("branche_name", CommonMainString0.branche_name)
  map.put("button_back", CommonMainString0.button_back)
  map.put("cache_size", CommonMainString0.cache_size)
  map.put("cancel", CommonMainString0.cancel)
  map.put("check_for_update", CommonMainString0.check_for_update)
  map.put("choose_image_type", CommonMainString0.choose_image_type)
  map.put("choose_language", CommonMainString0.choose_language)
  map.put("choose_region", CommonMainString0.choose_region)
  map.put("choose_type", CommonMainString0.choose_type)
  map.put("close", CommonMainString0.close)
  map.put("collection_id", CommonMainString0.collection_id)
  map.put("column", CommonMainString0.column)
  map.put("coming_soon", CommonMainString0.coming_soon)
  map.put("confirm", CommonMainString0.confirm)
  map.put("confirm_delete_text", CommonMainString0.confirm_delete_text)
  map.put("connection_failed", CommonMainString0.connection_failed)
  map.put("connection_successful", CommonMainString0.connection_successful)
  map.put("connection_tiemout", CommonMainString0.connection_tiemout)
  map.put("contain_video", CommonMainString0.contain_video)
  map.put("current_version", CommonMainString0.current_version)
  map.put("dark_mode", CommonMainString0.dark_mode)
  map.put("data_not_found", CommonMainString0.data_not_found)
  map.put("data_under_construct", CommonMainString0.data_under_construct)
  map.put("default_branch", CommonMainString0.default_branch)
  map.put("delete", CommonMainString0.delete)
  map.put("device_brand", CommonMainString0.device_brand)
  map.put("device_model", CommonMainString0.device_model)
  map.put("device_version", CommonMainString0.device_version)
  map.put("display_mode", CommonMainString0.display_mode)
  map.put("display_mode_center", CommonMainString0.display_mode_center)
  map.put("display_mode_full", CommonMainString0.display_mode_full)
  map.put("display_mode_full_screen", CommonMainString0.display_mode_full_screen)
  map.put("display_orientation_horizontal", CommonMainString0.display_orientation_horizontal)
  map.put("display_orientation_vertical", CommonMainString0.display_orientation_vertical)
  map.put("display_style_bento", CommonMainString0.display_style_bento)
  map.put("display_style_calender", CommonMainString0.display_style_calender)
  map.put("display_style_carousel", CommonMainString0.display_style_carousel)
  map.put("display_style_cube", CommonMainString0.display_style_cube)
  map.put("display_style_fade", CommonMainString0.display_style_fade)
  map.put("display_style_frame_wall", CommonMainString0.display_style_frame_wall)
  map.put("display_style_reveal", CommonMainString0.display_style_reveal)
  map.put("display_style_slide", CommonMainString0.display_style_slide)
  map.put("donate", CommonMainString0.donate)
  map.put("dream_description", CommonMainString0.dream_description)
  map.put("dream_label", CommonMainString0.dream_label)
  map.put("edit", CommonMainString0.edit)
  map.put("error", CommonMainString0.error)
  map.put("feedback", CommonMainString0.feedback)
  map.put("follow_system", CommonMainString0.follow_system)
  map.put("forward", CommonMainString0.forward)
  map.put("frame_style", CommonMainString0.frame_style)
  map.put("frame_wall_fix_size", CommonMainString0.frame_wall_fix_size)
  map.put("frame_wall_random_size", CommonMainString0.frame_wall_random_size)
  map.put("github_access_token_tips_title", CommonMainString0.github_access_token_tips_title)
  map.put("github_acess_token_tips_1", CommonMainString0.github_acess_token_tips_1)
  map.put("github_acess_token_tips_2", CommonMainString0.github_acess_token_tips_2)
  map.put("github_acess_token_tips_3", CommonMainString0.github_acess_token_tips_3)
  map.put("github_acess_token_tips_4", CommonMainString0.github_acess_token_tips_4)
  map.put("github_acess_token_tips_5", CommonMainString0.github_acess_token_tips_5)
  map.put("have_no_permission", CommonMainString0.have_no_permission)
  map.put("hide_password", CommonMainString0.hide_password)
  map.put("home", CommonMainString0.home)
  map.put("host", CommonMainString0.host)
  map.put("host_is_invalid", CommonMainString0.host_is_invalid)
  map.put("interval", CommonMainString0.interval)
  map.put("interval_time_unit", CommonMainString0.interval_time_unit)
  map.put("ip_is_invalid", CommonMainString0.ip_is_invalid)
  map.put("its_in_beta_test", CommonMainString0.its_in_beta_test)
  map.put("la_ar", CommonMainString0.la_ar)
  map.put("la_az", CommonMainString0.la_az)
  map.put("la_be", CommonMainString0.la_be)
  map.put("la_da", CommonMainString0.la_da)
  map.put("la_de", CommonMainString0.la_de)
  map.put("la_en_US", CommonMainString0.la_en_US)
  map.put("la_es", CommonMainString0.la_es)
  map.put("la_fa", CommonMainString0.la_fa)
  map.put("la_fil", CommonMainString0.la_fil)
  map.put("la_fr", CommonMainString0.la_fr)
  map.put("la_hu", CommonMainString0.la_hu)
  map.put("la_in", CommonMainString0.la_in)
  map.put("la_it", CommonMainString0.la_it)
  map.put("la_ja", CommonMainString0.la_ja)
  map.put("la_ko", CommonMainString0.la_ko)
  map.put("la_ms", CommonMainString0.la_ms)
  map.put("la_nl", CommonMainString0.la_nl)
  map.put("la_pl", CommonMainString0.la_pl)
  map.put("la_pt_BR", CommonMainString0.la_pt_BR)
  map.put("la_ru", CommonMainString0.la_ru)
  map.put("la_sr", CommonMainString0.la_sr)
  map.put("la_tr", CommonMainString0.la_tr)
  map.put("la_uk", CommonMainString0.la_uk)
  map.put("la_vi", CommonMainString0.la_vi)
  map.put("la_zh_CN", CommonMainString0.la_zh_CN)
  map.put("la_zh_TW", CommonMainString0.la_zh_TW)
  map.put("language", CommonMainString0.language)
  map.put("language_of_film_content", CommonMainString0.language_of_film_content)
  map.put("leave_blank_is_default_branch", CommonMainString0.leave_blank_is_default_branch)
  map.put("link", CommonMainString0.link)
  map.put("link_to", CommonMainString0.link_to)
  map.put("loading", CommonMainString0.loading)
  map.put("local_source_name", CommonMainString0.local_source_name)
  map.put("matrix_size", CommonMainString0.matrix_size)
  map.put("membership", CommonMainString0.membership)
  map.put("minutes", CommonMainString0.minutes)
  map.put("my", CommonMainString0.my)
  map.put("name_is_invalid", CommonMainString0.name_is_invalid)
  map.put("name_require_hint", CommonMainString0.name_require_hint)
  map.put("notification_channel_id", CommonMainString0.notification_channel_id)
  map.put("notification_title", CommonMainString0.notification_title)
  map.put("open_source_license", CommonMainString0.open_source_license)
  map.put("orientation", CommonMainString0.orientation)
  map.put("password", CommonMainString0.password)
  map.put("path", CommonMainString0.path)
  map.put("path_is_invalid", CommonMainString0.path_is_invalid)
  map.put("play", CommonMainString0.play)
  map.put("port", CommonMainString0.port)
  map.put("port_is_invalid", CommonMainString0.port_is_invalid)
  map.put("privacy_policy", CommonMainString0.privacy_policy)
  map.put("rate", CommonMainString0.rate)
  map.put("recursive_source_folder", CommonMainString0.recursive_source_folder)
  map.put("repo_name", CommonMainString0.repo_name)
  map.put("repo_owner", CommonMainString0.repo_owner)
  map.put("repo_url_require_hint", CommonMainString0.repo_url_require_hint)
  map.put("request_permission", CommonMainString0.request_permission)
  map.put("row", CommonMainString0.row)
  map.put("save", CommonMainString0.save)
  map.put("save_success", CommonMainString0.save_success)
  map.put("second", CommonMainString0.second)
  map.put("settings", CommonMainString0.settings)
  map.put("share", CommonMainString0.share)
  map.put("share_to_others", CommonMainString0.share_to_others)
  map.put("show_content_meta_info", CommonMainString0.show_content_meta_info)
  map.put("show_movie_from_selected_region", CommonMainString0.show_movie_from_selected_region)
  map.put("show_password", CommonMainString0.show_password)
  map.put("show_time_and_date", CommonMainString0.show_time_and_date)
  map.put("show_time_progress_indicator", CommonMainString0.show_time_progress_indicator)
  map.put("showcase_about", CommonMainString0.showcase_about)
  map.put("showcase_general", CommonMainString0.showcase_general)
  map.put("showcase_preview", CommonMainString0.showcase_preview)
  map.put("showcase_source_settings", CommonMainString0.showcase_source_settings)
  map.put("showcase_style", CommonMainString0.showcase_style)
  map.put("sort_rule", CommonMainString0.sort_rule)
  map.put("sort_rule_date_asc", CommonMainString0.sort_rule_date_asc)
  map.put("sort_rule_date_desc", CommonMainString0.sort_rule_date_desc)
  map.put("sort_rule_name_asc", CommonMainString0.sort_rule_name_asc)
  map.put("sort_rule_name_desc", CommonMainString0.sort_rule_name_desc)
  map.put("sort_rule_random", CommonMainString0.sort_rule_random)
  map.put("source", CommonMainString0.source)
  map.put("source_name", CommonMainString0.source_name)
  map.put("source_name_already_exists", CommonMainString0.source_name_already_exists)
  map.put("source_preference", CommonMainString0.source_preference)
  map.put("sources", CommonMainString0.sources)
  map.put("sub_folder_hint", CommonMainString0.sub_folder_hint)
  map.put("system", CommonMainString0.system)
  map.put("system_default", CommonMainString0.system_default)
  map.put("system_screensaver", CommonMainString0.system_screensaver)
  map.put("telegram_channel", CommonMainString0.telegram_channel)
  map.put("test_connection", CommonMainString0.test_connection)
  map.put("thanks", CommonMainString0.thanks)
  map.put("the_number_of_files_may_be_too_large_please_wait",
      CommonMainString0.the_number_of_files_may_be_too_large_please_wait)
  map.put("time_unit_minute", CommonMainString0.time_unit_minute)
  map.put("time_unit_second", CommonMainString0.time_unit_second)
  map.put("tmdb_au", CommonMainString0.tmdb_au)
  map.put("tmdb_ca", CommonMainString0.tmdb_ca)
  map.put("tmdb_cn", CommonMainString0.tmdb_cn)
  map.put("tmdb_de", CommonMainString0.tmdb_de)
  map.put("tmdb_es", CommonMainString0.tmdb_es)
  map.put("tmdb_fr", CommonMainString0.tmdb_fr)
  map.put("tmdb_image_type_backdrop", CommonMainString0.tmdb_image_type_backdrop)
  map.put("tmdb_in", CommonMainString0.tmdb_in)
  map.put("tmdb_it", CommonMainString0.tmdb_it)
  map.put("tmdb_jp", CommonMainString0.tmdb_jp)
  map.put("tmdb_kr", CommonMainString0.tmdb_kr)
  map.put("tmdb_lang_de_de", CommonMainString0.tmdb_lang_de_de)
  map.put("tmdb_lang_en_us", CommonMainString0.tmdb_lang_en_us)
  map.put("tmdb_lang_es_es", CommonMainString0.tmdb_lang_es_es)
  map.put("tmdb_lang_fr_fr", CommonMainString0.tmdb_lang_fr_fr)
  map.put("tmdb_lang_it_it", CommonMainString0.tmdb_lang_it_it)
  map.put("tmdb_lang_ja_jp", CommonMainString0.tmdb_lang_ja_jp)
  map.put("tmdb_lang_ko_kr", CommonMainString0.tmdb_lang_ko_kr)
  map.put("tmdb_lang_ru_ru", CommonMainString0.tmdb_lang_ru_ru)
  map.put("tmdb_lang_type_poster", CommonMainString0.tmdb_lang_type_poster)
  map.put("tmdb_lang_zh_cn", CommonMainString0.tmdb_lang_zh_cn)
  map.put("tmdb_lang_zh_tw", CommonMainString0.tmdb_lang_zh_tw)
  map.put("tmdb_now_playing", CommonMainString0.tmdb_now_playing)
  map.put("tmdb_popular", CommonMainString0.tmdb_popular)
  map.put("tmdb_ru", CommonMainString0.tmdb_ru)
  map.put("tmdb_top_rated", CommonMainString0.tmdb_top_rated)
  map.put("tmdb_uk", CommonMainString0.tmdb_uk)
  map.put("tmdb_upcoming", CommonMainString0.tmdb_upcoming)
  map.put("tmdb_us", CommonMainString0.tmdb_us)
  map.put("topic_id_or_slug", CommonMainString0.topic_id_or_slug)
  map.put("under_construction", CommonMainString0.under_construction)
  map.put("unknown", CommonMainString0.unknown)
  map.put("unsplash_collections_photos", CommonMainString0.unsplash_collections_photos)
  map.put("unsplash_feed_photos", CommonMainString0.unsplash_feed_photos)
  map.put("unsplash_topics_photos", CommonMainString0.unsplash_topics_photos)
  map.put("unsplash_users_collections", CommonMainString0.unsplash_users_collections)
  map.put("unsplash_users_likes", CommonMainString0.unsplash_users_likes)
  map.put("unsplash_users_photos", CommonMainString0.unsplash_users_photos)
  map.put("unsupport_type", CommonMainString0.unsupport_type)
  map.put("unsupported_type", CommonMainString0.unsupported_type)
  map.put("up_to_date", CommonMainString0.up_to_date)
  map.put("update", CommonMainString0.update)
  map.put("url_is_invalid", CommonMainString0.url_is_invalid)
  map.put("user", CommonMainString0.user)
  map.put("userName", CommonMainString0.userName)
  map.put("welcome", CommonMainString0.welcome)
  map.put("zoom_effect", CommonMainString0.zoom_effect)
}

internal val Res.string.Url: StringResource
  get() = CommonMainString0.Url

private fun init_Url(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Url", "Url",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr", 10,
    15),
    )
)

internal val Res.string._off: StringResource
  get() = CommonMainString0._off

private fun init__off(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:_off", "_off",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr", 26,
    16),
    )
)

internal val Res.string._on: StringResource
  get() = CommonMainString0._on

private fun init__on(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:_on", "_on",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr", 43,
    15),
    )
)

internal val Res.string.about: StringResource
  get() = CommonMainString0.about

private fun init_about(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about", "about",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr", 59,
    21),
    )
)

internal val Res.string.ad_mob_banner_id: StringResource
  get() = CommonMainString0.ad_mob_banner_id

private fun init_ad_mob_banner_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ad_mob_banner_id", "ad_mob_banner_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr", 81,
    76),
    )
)

internal val Res.string.ad_mob_list_item_native_id: StringResource
  get() = CommonMainString0.ad_mob_list_item_native_id

private fun init_ad_mob_list_item_native_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ad_mob_list_item_native_id", "ad_mob_list_item_native_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    158, 86),
    )
)

internal val Res.string.ad_mob_main_page_native_id: StringResource
  get() = CommonMainString0.ad_mob_main_page_native_id

private fun init_ad_mob_main_page_native_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ad_mob_main_page_native_id", "ad_mob_main_page_native_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    245, 86),
    )
)

internal val Res.string.ad_mob_setting_page_native_id: StringResource
  get() = CommonMainString0.ad_mob_setting_page_native_id

private fun init_ad_mob_setting_page_native_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ad_mob_setting_page_native_id", "ad_mob_setting_page_native_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    332, 89),
    )
)

internal val Res.string.ad_mob_splash_id: StringResource
  get() = CommonMainString0.ad_mob_splash_id

private fun init_ad_mob_splash_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ad_mob_splash_id", "ad_mob_splash_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    422, 76),
    )
)

internal val Res.string.ad_mob_test_banner_id: StringResource
  get() = CommonMainString0.ad_mob_test_banner_id

private fun init_ad_mob_test_banner_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ad_mob_test_banner_id", "ad_mob_test_banner_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    499, 81),
    )
)

internal val Res.string.ad_mob_test_list_item_native_1_id: StringResource
  get() = CommonMainString0.ad_mob_test_list_item_native_1_id

private fun init_ad_mob_test_list_item_native_1_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ad_mob_test_list_item_native_1_id", "ad_mob_test_list_item_native_1_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    581, 93),
    )
)

internal val Res.string.ad_mob_test_list_item_native_id: StringResource
  get() = CommonMainString0.ad_mob_test_list_item_native_id

private fun init_ad_mob_test_list_item_native_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ad_mob_test_list_item_native_id", "ad_mob_test_list_item_native_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    675, 91),
    )
)

internal val Res.string.ad_mob_test_splash_id: StringResource
  get() = CommonMainString0.ad_mob_test_splash_id

private fun init_ad_mob_test_splash_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ad_mob_test_splash_id", "ad_mob_test_splash_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    767, 81),
    )
)

internal val Res.string.add: StringResource
  get() = CommonMainString0.add

private fun init_add(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add", "add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    919, 15),
    )
)

internal val Res.string.addSource: StringResource
  get() = CommonMainString0.addSource

private fun init_addSource(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:addSource", "addSource",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    849, 33),
    )
)

internal val Res.string.add_success: StringResource
  get() = CommonMainString0.add_success

private fun init_add_success(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_success", "add_success",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    883, 35),
    )
)

internal val Res.string.ads: StringResource
  get() = CommonMainString0.ads

private fun init_ads(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ads", "ads",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    975, 15),
    )
)

internal val Res.string.ads_loading: StringResource
  get() = CommonMainString0.ads_loading

private fun init_ads_loading(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ads_loading", "ads_loading",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    935, 39),
    )
)

internal val Res.string.anonymous_usage: StringResource
  get() = CommonMainString0.anonymous_usage

private fun init_anonymous_usage(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:anonymous_usage", "anonymous_usage",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    991, 43),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1035, 28),
    )
)

internal val Res.string.app_update_failed: StringResource
  get() = CommonMainString0.app_update_failed

private fun init_app_update_failed(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:app_update_failed", "app_update_failed",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1064, 77),
    )
)

internal val Res.string.are_you_clear_cache: StringResource
  get() = CommonMainString0.are_you_clear_cache

private fun init_are_you_clear_cache(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:are_you_clear_cache", "are_you_clear_cache",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1142, 71),
    )
)

internal val Res.string.auto_open_latest_source: StringResource
  get() = CommonMainString0.auto_open_latest_source

private fun init_auto_open_latest_source(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:auto_open_latest_source", "auto_open_latest_source",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1214, 63),
    )
)

internal val Res.string.auto_play: StringResource
  get() = CommonMainString0.auto_play

private fun init_auto_play(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:auto_play", "auto_play",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1278, 41),
    )
)

internal val Res.string.auto_refresh_source_content: StringResource
  get() = CommonMainString0.auto_refresh_source_content

private fun init_auto_refresh_source_content(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:auto_refresh_source_content", "auto_refresh_source_content",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1320, 71),
    )
)

internal val Res.string.auto_update: StringResource
  get() = CommonMainString0.auto_update

private fun init_auto_update(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:auto_update", "auto_update",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1392, 35),
    )
)

internal val Res.string.backward: StringResource
  get() = CommonMainString0.backward

private fun init_backward(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:backward", "backward",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1428, 28),
    )
)

internal val Res.string.branch_name_is_invalid: StringResource
  get() = CommonMainString0.branch_name_is_invalid

private fun init_branch_name_is_invalid(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:branch_name_is_invalid", "branch_name_is_invalid",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1457, 62),
    )
)

internal val Res.string.branche_name: StringResource
  get() = CommonMainString0.branche_name

private fun init_branche_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:branche_name", "branche_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1520, 36),
    )
)

internal val Res.string.button_back: StringResource
  get() = CommonMainString0.button_back

private fun init_button_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:button_back", "button_back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1557, 27),
    )
)

internal val Res.string.cache_size: StringResource
  get() = CommonMainString0.cache_size

private fun init_cache_size(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cache_size", "cache_size",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1585, 34),
    )
)

internal val Res.string.cancel: StringResource
  get() = CommonMainString0.cancel

private fun init_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cancel", "cancel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1620, 22),
    )
)

internal val Res.string.check_for_update: StringResource
  get() = CommonMainString0.check_for_update

private fun init_check_for_update(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:check_for_update", "check_for_update",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1643, 48),
    )
)

internal val Res.string.choose_image_type: StringResource
  get() = CommonMainString0.choose_image_type

private fun init_choose_image_type(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:choose_image_type", "choose_image_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1692, 49),
    )
)

internal val Res.string.choose_language: StringResource
  get() = CommonMainString0.choose_language

private fun init_choose_language(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_language", "choose_language",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1742, 43),
    )
)

internal val Res.string.choose_region: StringResource
  get() = CommonMainString0.choose_region

private fun init_choose_region(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_region", "choose_region",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1786, 41),
    )
)

internal val Res.string.choose_type: StringResource
  get() = CommonMainString0.choose_type

private fun init_choose_type(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_type", "choose_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1828, 35),
    )
)

internal val Res.string.close: StringResource
  get() = CommonMainString0.close

private fun init_close(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:close", "close",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1864, 21),
    )
)

internal val Res.string.collection_id: StringResource
  get() = CommonMainString0.collection_id

private fun init_collection_id(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:collection_id", "collection_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1886, 41),
    )
)

internal val Res.string.column: StringResource
  get() = CommonMainString0.column

private fun init_column(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:column", "column",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1928, 22),
    )
)

internal val Res.string.coming_soon: StringResource
  get() = CommonMainString0.coming_soon

private fun init_coming_soon(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:coming_soon", "coming_soon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1951, 35),
    )
)

internal val Res.string.confirm: StringResource
  get() = CommonMainString0.confirm

private fun init_confirm(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:confirm", "confirm",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2059, 27),
    )
)

internal val Res.string.confirm_delete_text: StringResource
  get() = CommonMainString0.confirm_delete_text

private fun init_confirm_delete_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_delete_text", "confirm_delete_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    1987, 71),
    )
)

internal val Res.string.connection_failed: StringResource
  get() = CommonMainString0.connection_failed

private fun init_connection_failed(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:connection_failed", "connection_failed",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2087, 49),
    )
)

internal val Res.string.connection_successful: StringResource
  get() = CommonMainString0.connection_successful

private fun init_connection_successful(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:connection_successful", "connection_successful",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2137, 57),
    )
)

internal val Res.string.connection_tiemout: StringResource
  get() = CommonMainString0.connection_tiemout

private fun init_connection_tiemout(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:connection_tiemout", "connection_tiemout",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2195, 50),
    )
)

internal val Res.string.contain_video: StringResource
  get() = CommonMainString0.contain_video

private fun init_contain_video(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contain_video", "contain_video",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2246, 41),
    )
)

internal val Res.string.current_version: StringResource
  get() = CommonMainString0.current_version

private fun init_current_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:current_version", "current_version",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2288, 43),
    )
)

internal val Res.string.dark_mode: StringResource
  get() = CommonMainString0.dark_mode

private fun init_dark_mode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dark_mode", "dark_mode",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2332, 33),
    )
)

internal val Res.string.data_not_found: StringResource
  get() = CommonMainString0.data_not_found

private fun init_data_not_found(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:data_not_found", "data_not_found",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2366, 42),
    )
)

internal val Res.string.data_under_construct: StringResource
  get() = CommonMainString0.data_under_construct

private fun init_data_under_construct(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:data_under_construct", "data_under_construct",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2409, 56),
    )
)

internal val Res.string.default_branch: StringResource
  get() = CommonMainString0.default_branch

private fun init_default_branch(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:default_branch", "default_branch",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2466, 42),
    )
)

internal val Res.string.delete: StringResource
  get() = CommonMainString0.delete

private fun init_delete(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:delete", "delete",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2509, 22),
    )
)

internal val Res.string.device_brand: StringResource
  get() = CommonMainString0.device_brand

private fun init_device_brand(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:device_brand", "device_brand",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2532, 28),
    )
)

internal val Res.string.device_model: StringResource
  get() = CommonMainString0.device_model

private fun init_device_model(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:device_model", "device_model",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2561, 28),
    )
)

internal val Res.string.device_version: StringResource
  get() = CommonMainString0.device_version

private fun init_device_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:device_version", "device_version",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2590, 42),
    )
)

internal val Res.string.display_mode: StringResource
  get() = CommonMainString0.display_mode

private fun init_display_mode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:display_mode", "display_mode",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2752, 36),
    )
)

internal val Res.string.display_mode_center: StringResource
  get() = CommonMainString0.display_mode_center

private fun init_display_mode_center(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_mode_center", "display_mode_center",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2633, 35),
    )
)

internal val Res.string.display_mode_full: StringResource
  get() = CommonMainString0.display_mode_full

private fun init_display_mode_full(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_mode_full", "display_mode_full",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2718, 33),
    )
)

internal val Res.string.display_mode_full_screen: StringResource
  get() = CommonMainString0.display_mode_full_screen

private fun init_display_mode_full_screen(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_mode_full_screen", "display_mode_full_screen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2669, 48),
    )
)

internal val Res.string.display_orientation_horizontal: StringResource
  get() = CommonMainString0.display_orientation_horizontal

private fun init_display_orientation_horizontal(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_orientation_horizontal", "display_orientation_horizontal",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2789, 54),
    )
)

internal val Res.string.display_orientation_vertical: StringResource
  get() = CommonMainString0.display_orientation_vertical

private fun init_display_orientation_vertical(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_orientation_vertical", "display_orientation_vertical",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2844, 48),
    )
)

internal val Res.string.display_style_bento: StringResource
  get() = CommonMainString0.display_style_bento

private fun init_display_style_bento(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_style_bento", "display_style_bento",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2893, 35),
    )
)

internal val Res.string.display_style_calender: StringResource
  get() = CommonMainString0.display_style_calender

private fun init_display_style_calender(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_style_calender", "display_style_calender",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2929, 50),
    )
)

internal val Res.string.display_style_carousel: StringResource
  get() = CommonMainString0.display_style_carousel

private fun init_display_style_carousel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_style_carousel", "display_style_carousel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    2980, 42),
    )
)

internal val Res.string.display_style_cube: StringResource
  get() = CommonMainString0.display_style_cube

private fun init_display_style_cube(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_style_cube", "display_style_cube",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3023, 34),
    )
)

internal val Res.string.display_style_fade: StringResource
  get() = CommonMainString0.display_style_fade

private fun init_display_style_fade(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_style_fade", "display_style_fade",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3058, 34),
    )
)

internal val Res.string.display_style_frame_wall: StringResource
  get() = CommonMainString0.display_style_frame_wall

private fun init_display_style_frame_wall(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_style_frame_wall", "display_style_frame_wall",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3093, 48),
    )
)

internal val Res.string.display_style_reveal: StringResource
  get() = CommonMainString0.display_style_reveal

private fun init_display_style_reveal(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_style_reveal", "display_style_reveal",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3142, 36),
    )
)

internal val Res.string.display_style_slide: StringResource
  get() = CommonMainString0.display_style_slide

private fun init_display_style_slide(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:display_style_slide", "display_style_slide",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3179, 35),
    )
)

internal val Res.string.donate: StringResource
  get() = CommonMainString0.donate

private fun init_donate(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:donate", "donate",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3215, 22),
    )
)

internal val Res.string.dream_description: StringResource
  get() = CommonMainString0.dream_description

private fun init_dream_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:dream_description", "dream_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3238, 37),
    )
)

internal val Res.string.dream_label: StringResource
  get() = CommonMainString0.dream_label

private fun init_dream_label(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dream_label", "dream_label",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3276, 31),
    )
)

internal val Res.string.edit: StringResource
  get() = CommonMainString0.edit

private fun init_edit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:edit", "edit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3308, 20),
    )
)

internal val Res.string.error: StringResource
  get() = CommonMainString0.error

private fun init_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error", "error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3329, 21),
    )
)

internal val Res.string.feedback: StringResource
  get() = CommonMainString0.feedback

private fun init_feedback(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:feedback", "feedback",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3351, 28),
    )
)

internal val Res.string.follow_system: StringResource
  get() = CommonMainString0.follow_system

private fun init_follow_system(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:follow_system", "follow_system",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3380, 41),
    )
)

internal val Res.string.forward: StringResource
  get() = CommonMainString0.forward

private fun init_forward(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:forward", "forward",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3422, 27),
    )
)

internal val Res.string.frame_style: StringResource
  get() = CommonMainString0.frame_style

private fun init_frame_style(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:frame_style", "frame_style",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3450, 35),
    )
)

internal val Res.string.frame_wall_fix_size: StringResource
  get() = CommonMainString0.frame_wall_fix_size

private fun init_frame_wall_fix_size(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:frame_wall_fix_size", "frame_wall_fix_size",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3486, 39),
    )
)

internal val Res.string.frame_wall_random_size: StringResource
  get() = CommonMainString0.frame_wall_random_size

private fun init_frame_wall_random_size(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:frame_wall_random_size", "frame_wall_random_size",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3526, 46),
    )
)

internal val Res.string.github_access_token_tips_title: StringResource
  get() = CommonMainString0.github_access_token_tips_title

private fun init_github_access_token_tips_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:github_access_token_tips_title", "github_access_token_tips_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3573, 70),
    )
)

internal val Res.string.github_acess_token_tips_1: StringResource
  get() = CommonMainString0.github_acess_token_tips_1

private fun init_github_acess_token_tips_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:github_acess_token_tips_1", "github_acess_token_tips_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    3644, 381),
    )
)

internal val Res.string.github_acess_token_tips_2: StringResource
  get() = CommonMainString0.github_acess_token_tips_2

private fun init_github_acess_token_tips_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:github_acess_token_tips_2", "github_acess_token_tips_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4026, 125),
    )
)

internal val Res.string.github_acess_token_tips_3: StringResource
  get() = CommonMainString0.github_acess_token_tips_3

private fun init_github_acess_token_tips_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:github_acess_token_tips_3", "github_acess_token_tips_3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4152, 137),
    )
)

internal val Res.string.github_acess_token_tips_4: StringResource
  get() = CommonMainString0.github_acess_token_tips_4

private fun init_github_acess_token_tips_4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:github_acess_token_tips_4", "github_acess_token_tips_4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4290, 225),
    )
)

internal val Res.string.github_acess_token_tips_5: StringResource
  get() = CommonMainString0.github_acess_token_tips_5

private fun init_github_acess_token_tips_5(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:github_acess_token_tips_5", "github_acess_token_tips_5",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4516, 153),
    )
)

internal val Res.string.have_no_permission: StringResource
  get() = CommonMainString0.have_no_permission

private fun init_have_no_permission(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:have_no_permission", "have_no_permission",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4670, 50),
    )
)

internal val Res.string.hide_password: StringResource
  get() = CommonMainString0.hide_password

private fun init_hide_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hide_password", "hide_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4721, 41),
    )
)

internal val Res.string.home: StringResource
  get() = CommonMainString0.home

private fun init_home(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:home", "home",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4763, 20),
    )
)

internal val Res.string.host: StringResource
  get() = CommonMainString0.host

private fun init_host(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:host", "host",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4828, 20),
    )
)

internal val Res.string.host_is_invalid: StringResource
  get() = CommonMainString0.host_is_invalid

private fun init_host_is_invalid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:host_is_invalid", "host_is_invalid",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4784, 43),
    )
)

internal val Res.string.interval: StringResource
  get() = CommonMainString0.interval

private fun init_interval(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:interval", "interval",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4900, 28),
    )
)

internal val Res.string.interval_time_unit: StringResource
  get() = CommonMainString0.interval_time_unit

private fun init_interval_time_unit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:interval_time_unit", "interval_time_unit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4849, 50),
    )
)

internal val Res.string.ip_is_invalid: StringResource
  get() = CommonMainString0.ip_is_invalid

private fun init_ip_is_invalid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ip_is_invalid", "ip_is_invalid",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4929, 41),
    )
)

internal val Res.string.its_in_beta_test: StringResource
  get() = CommonMainString0.its_in_beta_test

private fun init_its_in_beta_test(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:its_in_beta_test", "its_in_beta_test",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    4971, 216),
    )
)

internal val Res.string.la_ar: StringResource
  get() = CommonMainString0.la_ar

private fun init_la_ar(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_ar", "la_ar",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5188, 33),
    )
)

internal val Res.string.la_az: StringResource
  get() = CommonMainString0.la_az

private fun init_la_az(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_az", "la_az",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5222, 33),
    )
)

internal val Res.string.la_be: StringResource
  get() = CommonMainString0.la_be

private fun init_la_be(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_be", "la_be",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5256, 41),
    )
)

internal val Res.string.la_da: StringResource
  get() = CommonMainString0.la_da

private fun init_la_da(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_da", "la_da",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5298, 21),
    )
)

internal val Res.string.la_de: StringResource
  get() = CommonMainString0.la_de

private fun init_la_de(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_de", "la_de",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5320, 25),
    )
)

internal val Res.string.la_en_US: StringResource
  get() = CommonMainString0.la_en_US

private fun init_la_en_US(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_en_US", "la_en_US",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5346, 28),
    )
)

internal val Res.string.la_es: StringResource
  get() = CommonMainString0.la_es

private fun init_la_es(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_es", "la_es",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5375, 25),
    )
)

internal val Res.string.la_fa: StringResource
  get() = CommonMainString0.la_fa

private fun init_la_fa(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_fa", "la_fa",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5401, 29),
    )
)

internal val Res.string.la_fil: StringResource
  get() = CommonMainString0.la_fil

private fun init_la_fil(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_fil", "la_fil",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5431, 26),
    )
)

internal val Res.string.la_fr: StringResource
  get() = CommonMainString0.la_fr

private fun init_la_fr(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_fr", "la_fr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5458, 25),
    )
)

internal val Res.string.la_hu: StringResource
  get() = CommonMainString0.la_hu

private fun init_la_hu(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_hu", "la_hu",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5484, 21),
    )
)

internal val Res.string.la_in: StringResource
  get() = CommonMainString0.la_in

private fun init_la_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_in", "la_in",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5506, 25),
    )
)

internal val Res.string.la_it: StringResource
  get() = CommonMainString0.la_it

private fun init_la_it(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_it", "la_it",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5532, 25),
    )
)

internal val Res.string.la_ja: StringResource
  get() = CommonMainString0.la_ja

private fun init_la_ja(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_ja", "la_ja",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5558, 25),
    )
)

internal val Res.string.la_ko: StringResource
  get() = CommonMainString0.la_ko

private fun init_la_ko(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_ko", "la_ko",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5584, 25),
    )
)

internal val Res.string.la_ms: StringResource
  get() = CommonMainString0.la_ms

private fun init_la_ms(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_ms", "la_ms",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5610, 21),
    )
)

internal val Res.string.la_nl: StringResource
  get() = CommonMainString0.la_nl

private fun init_la_nl(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_nl", "la_nl",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5632, 29),
    )
)

internal val Res.string.la_pl: StringResource
  get() = CommonMainString0.la_pl

private fun init_la_pl(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_pl", "la_pl",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5662, 21),
    )
)

internal val Res.string.la_pt_BR: StringResource
  get() = CommonMainString0.la_pt_BR

private fun init_la_pt_BR(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_pt_BR", "la_pt_BR",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5684, 44),
    )
)

internal val Res.string.la_ru: StringResource
  get() = CommonMainString0.la_ru

private fun init_la_ru(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_ru", "la_ru",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5729, 33),
    )
)

internal val Res.string.la_sr: StringResource
  get() = CommonMainString0.la_sr

private fun init_la_sr(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_sr", "la_sr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5763, 29),
    )
)

internal val Res.string.la_tr: StringResource
  get() = CommonMainString0.la_tr

private fun init_la_tr(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_tr", "la_tr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5793, 25),
    )
)

internal val Res.string.la_uk: StringResource
  get() = CommonMainString0.la_uk

private fun init_la_uk(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_uk", "la_uk",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5819, 41),
    )
)

internal val Res.string.la_vi: StringResource
  get() = CommonMainString0.la_vi

private fun init_la_vi(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_vi", "la_vi",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5861, 33),
    )
)

internal val Res.string.la_zh_CN: StringResource
  get() = CommonMainString0.la_zh_CN

private fun init_la_zh_CN(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_zh_CN", "la_zh_CN",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5895, 32),
    )
)

internal val Res.string.la_zh_TW: StringResource
  get() = CommonMainString0.la_zh_TW

private fun init_la_zh_TW(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:la_zh_TW", "la_zh_TW",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5928, 32),
    )
)

internal val Res.string.language: StringResource
  get() = CommonMainString0.language

private fun init_language(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:language", "language",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6038, 28),
    )
)

internal val Res.string.language_of_film_content: StringResource
  get() = CommonMainString0.language_of_film_content

private fun init_language_of_film_content(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:language_of_film_content", "language_of_film_content",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    5961, 76),
    )
)

internal val Res.string.leave_blank_is_default_branch: StringResource
  get() = CommonMainString0.leave_blank_is_default_branch

private fun init_leave_blank_is_default_branch(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:leave_blank_is_default_branch", "leave_blank_is_default_branch",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6067, 81),
    )
)

internal val Res.string.link: StringResource
  get() = CommonMainString0.link

private fun init_link(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:link", "link",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6177, 20),
    )
)

internal val Res.string.link_to: StringResource
  get() = CommonMainString0.link_to

private fun init_link_to(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:link_to", "link_to",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6149, 27),
    )
)

internal val Res.string.loading: StringResource
  get() = CommonMainString0.loading

private fun init_loading(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:loading", "loading",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6198, 31),
    )
)

internal val Res.string.local_source_name: StringResource
  get() = CommonMainString0.local_source_name

private fun init_local_source_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:local_source_name", "local_source_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6230, 49),
    )
)

internal val Res.string.matrix_size: StringResource
  get() = CommonMainString0.matrix_size

private fun init_matrix_size(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:matrix_size", "matrix_size",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6280, 35),
    )
)

internal val Res.string.membership: StringResource
  get() = CommonMainString0.membership

private fun init_membership(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:membership", "membership",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6316, 34),
    )
)

internal val Res.string.minutes: StringResource
  get() = CommonMainString0.minutes

private fun init_minutes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:minutes", "minutes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6351, 27),
    )
)

internal val Res.string.my: StringResource
  get() = CommonMainString0.my

private fun init_my(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:my", "my",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6379, 14),
    )
)

internal val Res.string.name_is_invalid: StringResource
  get() = CommonMainString0.name_is_invalid

private fun init_name_is_invalid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:name_is_invalid", "name_is_invalid",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6394, 43),
    )
)

internal val Res.string.name_require_hint: StringResource
  get() = CommonMainString0.name_require_hint

private fun init_name_require_hint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:name_require_hint", "name_require_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6438, 45),
    )
)

internal val Res.string.notification_channel_id: StringResource
  get() = CommonMainString0.notification_channel_id

private fun init_notification_channel_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:notification_channel_id", "notification_channel_id",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6484, 59),
    )
)

internal val Res.string.notification_title: StringResource
  get() = CommonMainString0.notification_title

private fun init_notification_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:notification_title", "notification_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6544, 50),
    )
)

internal val Res.string.open_source_license: StringResource
  get() = CommonMainString0.open_source_license

private fun init_open_source_license(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:open_source_license", "open_source_license",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6595, 55),
    )
)

internal val Res.string.orientation: StringResource
  get() = CommonMainString0.orientation

private fun init_orientation(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:orientation", "orientation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6651, 35),
    )
)

internal val Res.string.password: StringResource
  get() = CommonMainString0.password

private fun init_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:password", "password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6687, 28),
    )
)

internal val Res.string.path: StringResource
  get() = CommonMainString0.path

private fun init_path(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:path", "path",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6760, 20),
    )
)

internal val Res.string.path_is_invalid: StringResource
  get() = CommonMainString0.path_is_invalid

private fun init_path_is_invalid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:path_is_invalid", "path_is_invalid",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6716, 43),
    )
)

internal val Res.string.play: StringResource
  get() = CommonMainString0.play

private fun init_play(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:play", "play",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6781, 20),
    )
)

internal val Res.string.port: StringResource
  get() = CommonMainString0.port

private fun init_port(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:port", "port",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6846, 20),
    )
)

internal val Res.string.port_is_invalid: StringResource
  get() = CommonMainString0.port_is_invalid

private fun init_port_is_invalid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:port_is_invalid", "port_is_invalid",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6802, 43),
    )
)

internal val Res.string.privacy_policy: StringResource
  get() = CommonMainString0.privacy_policy

private fun init_privacy_policy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:privacy_policy", "privacy_policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6867, 42),
    )
)

internal val Res.string.rate: StringResource
  get() = CommonMainString0.rate

private fun init_rate(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rate", "rate",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6910, 24),
    )
)

internal val Res.string.recursive_source_folder: StringResource
  get() = CommonMainString0.recursive_source_folder

private fun init_recursive_source_folder(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:recursive_source_folder", "recursive_source_folder",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    6935, 75),
    )
)

internal val Res.string.repo_name: StringResource
  get() = CommonMainString0.repo_name

private fun init_repo_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:repo_name", "repo_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7011, 25),
    )
)

internal val Res.string.repo_owner: StringResource
  get() = CommonMainString0.repo_owner

private fun init_repo_owner(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:repo_owner", "repo_owner",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7037, 26),
    )
)

internal val Res.string.repo_url_require_hint: StringResource
  get() = CommonMainString0.repo_url_require_hint

private fun init_repo_url_require_hint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:repo_url_require_hint", "repo_url_require_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7064, 57),
    )
)

internal val Res.string.request_permission: StringResource
  get() = CommonMainString0.request_permission

private fun init_request_permission(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:request_permission", "request_permission",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7122, 50),
    )
)

internal val Res.string.row: StringResource
  get() = CommonMainString0.row

private fun init_row(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:row", "row",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7173, 15),
    )
)

internal val Res.string.save: StringResource
  get() = CommonMainString0.save

private fun init_save(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:save", "save",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7226, 20),
    )
)

internal val Res.string.save_success: StringResource
  get() = CommonMainString0.save_success

private fun init_save_success(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:save_success", "save_success",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7189, 36),
    )
)

internal val Res.string.second: StringResource
  get() = CommonMainString0.second

private fun init_second(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:second", "second",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7247, 26),
    )
)

internal val Res.string.settings: StringResource
  get() = CommonMainString0.settings

private fun init_settings(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings", "settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7274, 28),
    )
)

internal val Res.string.share: StringResource
  get() = CommonMainString0.share

private fun init_share(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:share", "share",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7435, 21),
    )
)

internal val Res.string.share_to_others: StringResource
  get() = CommonMainString0.share_to_others

private fun init_share_to_others(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:share_to_others", "share_to_others",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7303, 131),
    )
)

internal val Res.string.show_content_meta_info: StringResource
  get() = CommonMainString0.show_content_meta_info

private fun init_show_content_meta_info(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:show_content_meta_info", "show_content_meta_info",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7457, 62),
    )
)

internal val Res.string.show_movie_from_selected_region: StringResource
  get() = CommonMainString0.show_movie_from_selected_region

private fun init_show_movie_from_selected_region(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:show_movie_from_selected_region", "show_movie_from_selected_region",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7520, 83),
    )
)

internal val Res.string.show_password: StringResource
  get() = CommonMainString0.show_password

private fun init_show_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:show_password", "show_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7604, 41),
    )
)

internal val Res.string.show_time_and_date: StringResource
  get() = CommonMainString0.show_time_and_date

private fun init_show_time_and_date(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:show_time_and_date", "show_time_and_date",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7646, 50),
    )
)

internal val Res.string.show_time_progress_indicator: StringResource
  get() = CommonMainString0.show_time_progress_indicator

private fun init_show_time_progress_indicator(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:show_time_progress_indicator", "show_time_progress_indicator",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7697, 76),
    )
)

internal val Res.string.showcase_about: StringResource
  get() = CommonMainString0.showcase_about

private fun init_showcase_about(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:showcase_about", "showcase_about",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7774, 30),
    )
)

internal val Res.string.showcase_general: StringResource
  get() = CommonMainString0.showcase_general

private fun init_showcase_general(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:showcase_general", "showcase_general",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7805, 36),
    )
)

internal val Res.string.showcase_preview: StringResource
  get() = CommonMainString0.showcase_preview

private fun init_showcase_preview(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:showcase_preview", "showcase_preview",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7842, 36),
    )
)

internal val Res.string.showcase_source_settings: StringResource
  get() = CommonMainString0.showcase_source_settings

private fun init_showcase_source_settings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:showcase_source_settings", "showcase_source_settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7879, 52),
    )
)

internal val Res.string.showcase_style: StringResource
  get() = CommonMainString0.showcase_style

private fun init_showcase_style(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:showcase_style", "showcase_style",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7932, 30),
    )
)

internal val Res.string.sort_rule: StringResource
  get() = CommonMainString0.sort_rule

private fun init_sort_rule(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sort_rule", "sort_rule",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8154, 29),
    )
)

internal val Res.string.sort_rule_date_asc: StringResource
  get() = CommonMainString0.sort_rule_date_asc

private fun init_sort_rule_date_asc(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sort_rule_date_asc", "sort_rule_date_asc",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    7963, 38),
    )
)

internal val Res.string.sort_rule_date_desc: StringResource
  get() = CommonMainString0.sort_rule_date_desc

private fun init_sort_rule_date_desc(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sort_rule_date_desc", "sort_rule_date_desc",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8002, 39),
    )
)

internal val Res.string.sort_rule_name_asc: StringResource
  get() = CommonMainString0.sort_rule_name_asc

private fun init_sort_rule_name_asc(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sort_rule_name_asc", "sort_rule_name_asc",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8042, 38),
    )
)

internal val Res.string.sort_rule_name_desc: StringResource
  get() = CommonMainString0.sort_rule_name_desc

private fun init_sort_rule_name_desc(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sort_rule_name_desc", "sort_rule_name_desc",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8081, 39),
    )
)

internal val Res.string.sort_rule_random: StringResource
  get() = CommonMainString0.sort_rule_random

private fun init_sort_rule_random(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sort_rule_random", "sort_rule_random",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8121, 32),
    )
)

internal val Res.string.source: StringResource
  get() = CommonMainString0.source

private fun init_source(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:source", "source",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8369, 22),
    )
)

internal val Res.string.source_name: StringResource
  get() = CommonMainString0.source_name

private fun init_source_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:source_name", "source_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8255, 35),
    )
)

internal val Res.string.source_name_already_exists: StringResource
  get() = CommonMainString0.source_name_already_exists

private fun init_source_name_already_exists(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:source_name_already_exists", "source_name_already_exists",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8184, 70),
    )
)

internal val Res.string.source_preference: StringResource
  get() = CommonMainString0.source_preference

private fun init_source_preference(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:source_preference", "source_preference",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8291, 49),
    )
)

internal val Res.string.sources: StringResource
  get() = CommonMainString0.sources

private fun init_sources(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sources", "sources",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8341, 27),
    )
)

internal val Res.string.sub_folder_hint: StringResource
  get() = CommonMainString0.sub_folder_hint

private fun init_sub_folder_hint(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sub_folder_hint", "sub_folder_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8392, 39),
    )
)

internal val Res.string.system: StringResource
  get() = CommonMainString0.system

private fun init_system(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:system", "system",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8526, 22),
    )
)

internal val Res.string.system_default: StringResource
  get() = CommonMainString0.system_default

private fun init_system_default(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:system_default", "system_default",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8432, 42),
    )
)

internal val Res.string.system_screensaver: StringResource
  get() = CommonMainString0.system_screensaver

private fun init_system_screensaver(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:system_screensaver", "system_screensaver",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8475, 50),
    )
)

internal val Res.string.telegram_channel: StringResource
  get() = CommonMainString0.telegram_channel

private fun init_telegram_channel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:telegram_channel", "telegram_channel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8549, 48),
    )
)

internal val Res.string.test_connection: StringResource
  get() = CommonMainString0.test_connection

private fun init_test_connection(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:test_connection", "test_connection",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8598, 43),
    )
)

internal val Res.string.thanks: StringResource
  get() = CommonMainString0.thanks

private fun init_thanks(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:thanks", "thanks",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8642, 22),
    )
)

internal val Res.string.the_number_of_files_may_be_too_large_please_wait: StringResource
  get() = CommonMainString0.the_number_of_files_may_be_too_large_please_wait

private fun init_the_number_of_files_may_be_too_large_please_wait(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:the_number_of_files_may_be_too_large_please_wait",
    "the_number_of_files_may_be_too_large_please_wait",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8665, 124),
    )
)

internal val Res.string.time_unit_minute: StringResource
  get() = CommonMainString0.time_unit_minute

private fun init_time_unit_minute(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:time_unit_minute", "time_unit_minute",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8790, 32),
    )
)

internal val Res.string.time_unit_second: StringResource
  get() = CommonMainString0.time_unit_second

private fun init_time_unit_second(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:time_unit_second", "time_unit_second",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8823, 32),
    )
)

internal val Res.string.tmdb_au: StringResource
  get() = CommonMainString0.tmdb_au

private fun init_tmdb_au(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_au", "tmdb_au",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8856, 27),
    )
)

internal val Res.string.tmdb_ca: StringResource
  get() = CommonMainString0.tmdb_ca

private fun init_tmdb_ca(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_ca", "tmdb_ca",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8884, 23),
    )
)

internal val Res.string.tmdb_cn: StringResource
  get() = CommonMainString0.tmdb_cn

private fun init_tmdb_cn(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_cn", "tmdb_cn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8908, 23),
    )
)

internal val Res.string.tmdb_de: StringResource
  get() = CommonMainString0.tmdb_de

private fun init_tmdb_de(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_de", "tmdb_de",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8932, 27),
    )
)

internal val Res.string.tmdb_es: StringResource
  get() = CommonMainString0.tmdb_es

private fun init_tmdb_es(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_es", "tmdb_es",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8960, 23),
    )
)

internal val Res.string.tmdb_fr: StringResource
  get() = CommonMainString0.tmdb_fr

private fun init_tmdb_fr(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_fr", "tmdb_fr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    8984, 23),
    )
)

internal val Res.string.tmdb_image_type_backdrop: StringResource
  get() = CommonMainString0.tmdb_image_type_backdrop

private fun init_tmdb_image_type_backdrop(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tmdb_image_type_backdrop", "tmdb_image_type_backdrop",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9008, 44),
    )
)

internal val Res.string.tmdb_in: StringResource
  get() = CommonMainString0.tmdb_in

private fun init_tmdb_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_in", "tmdb_in",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9053, 23),
    )
)

internal val Res.string.tmdb_it: StringResource
  get() = CommonMainString0.tmdb_it

private fun init_tmdb_it(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_it", "tmdb_it",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9077, 23),
    )
)

internal val Res.string.tmdb_jp: StringResource
  get() = CommonMainString0.tmdb_jp

private fun init_tmdb_jp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_jp", "tmdb_jp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9101, 23),
    )
)

internal val Res.string.tmdb_kr: StringResource
  get() = CommonMainString0.tmdb_kr

private fun init_tmdb_kr(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_kr", "tmdb_kr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9125, 23),
    )
)

internal val Res.string.tmdb_lang_de_de: StringResource
  get() = CommonMainString0.tmdb_lang_de_de

private fun init_tmdb_lang_de_de(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_de_de", "tmdb_lang_de_de",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9149, 31),
    )
)

internal val Res.string.tmdb_lang_en_us: StringResource
  get() = CommonMainString0.tmdb_lang_en_us

private fun init_tmdb_lang_en_us(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_en_us", "tmdb_lang_en_us",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9181, 35),
    )
)

internal val Res.string.tmdb_lang_es_es: StringResource
  get() = CommonMainString0.tmdb_lang_es_es

private fun init_tmdb_lang_es_es(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_es_es", "tmdb_lang_es_es",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9217, 35),
    )
)

internal val Res.string.tmdb_lang_fr_fr: StringResource
  get() = CommonMainString0.tmdb_lang_fr_fr

private fun init_tmdb_lang_fr_fr(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_fr_fr", "tmdb_lang_fr_fr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9253, 31),
    )
)

internal val Res.string.tmdb_lang_it_it: StringResource
  get() = CommonMainString0.tmdb_lang_it_it

private fun init_tmdb_lang_it_it(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_it_it", "tmdb_lang_it_it",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9285, 35),
    )
)

internal val Res.string.tmdb_lang_ja_jp: StringResource
  get() = CommonMainString0.tmdb_lang_ja_jp

private fun init_tmdb_lang_ja_jp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_ja_jp", "tmdb_lang_ja_jp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9321, 35),
    )
)

internal val Res.string.tmdb_lang_ko_kr: StringResource
  get() = CommonMainString0.tmdb_lang_ko_kr

private fun init_tmdb_lang_ko_kr(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_ko_kr", "tmdb_lang_ko_kr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9357, 31),
    )
)

internal val Res.string.tmdb_lang_ru_ru: StringResource
  get() = CommonMainString0.tmdb_lang_ru_ru

private fun init_tmdb_lang_ru_ru(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_ru_ru", "tmdb_lang_ru_ru",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9389, 35),
    )
)

internal val Res.string.tmdb_lang_type_poster: StringResource
  get() = CommonMainString0.tmdb_lang_type_poster

private fun init_tmdb_lang_type_poster(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_type_poster", "tmdb_lang_type_poster",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9425, 37),
    )
)

internal val Res.string.tmdb_lang_zh_cn: StringResource
  get() = CommonMainString0.tmdb_lang_zh_cn

private fun init_tmdb_lang_zh_cn(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_zh_cn", "tmdb_lang_zh_cn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9463, 47),
    )
)

internal val Res.string.tmdb_lang_zh_tw: StringResource
  get() = CommonMainString0.tmdb_lang_zh_tw

private fun init_tmdb_lang_zh_tw(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_lang_zh_tw", "tmdb_lang_zh_tw",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9511, 51),
    )
)

internal val Res.string.tmdb_now_playing: StringResource
  get() = CommonMainString0.tmdb_now_playing

private fun init_tmdb_now_playing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tmdb_now_playing", "tmdb_now_playing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9563, 40),
    )
)

internal val Res.string.tmdb_popular: StringResource
  get() = CommonMainString0.tmdb_popular

private fun init_tmdb_popular(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_popular", "tmdb_popular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9604, 32),
    )
)

internal val Res.string.tmdb_ru: StringResource
  get() = CommonMainString0.tmdb_ru

private fun init_tmdb_ru(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_ru", "tmdb_ru",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9637, 23),
    )
)

internal val Res.string.tmdb_top_rated: StringResource
  get() = CommonMainString0.tmdb_top_rated

private fun init_tmdb_top_rated(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_top_rated", "tmdb_top_rated",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9661, 34),
    )
)

internal val Res.string.tmdb_uk: StringResource
  get() = CommonMainString0.tmdb_uk

private fun init_tmdb_uk(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_uk", "tmdb_uk",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9696, 35),
    )
)

internal val Res.string.tmdb_upcoming: StringResource
  get() = CommonMainString0.tmdb_upcoming

private fun init_tmdb_upcoming(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_upcoming", "tmdb_upcoming",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9732, 33),
    )
)

internal val Res.string.tmdb_us: StringResource
  get() = CommonMainString0.tmdb_us

private fun init_tmdb_us(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tmdb_us", "tmdb_us",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9766, 35),
    )
)

internal val Res.string.topic_id_or_slug: StringResource
  get() = CommonMainString0.topic_id_or_slug

private fun init_topic_id_or_slug(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:topic_id_or_slug", "topic_id_or_slug",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9802, 36),
    )
)

internal val Res.string.under_construction: StringResource
  get() = CommonMainString0.under_construction

private fun init_under_construction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:under_construction", "under_construction",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9839, 50),
    )
)

internal val Res.string.unknown: StringResource
  get() = CommonMainString0.unknown

private fun init_unknown(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:unknown", "unknown",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9890, 27),
    )
)

internal val Res.string.unsplash_collections_photos: StringResource
  get() = CommonMainString0.unsplash_collections_photos

private fun init_unsplash_collections_photos(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unsplash_collections_photos", "unsplash_collections_photos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9918, 63),
    )
)

internal val Res.string.unsplash_feed_photos: StringResource
  get() = CommonMainString0.unsplash_feed_photos

private fun init_unsplash_feed_photos(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unsplash_feed_photos", "unsplash_feed_photos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    9982, 44),
    )
)

internal val Res.string.unsplash_topics_photos: StringResource
  get() = CommonMainString0.unsplash_topics_photos

private fun init_unsplash_topics_photos(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unsplash_topics_photos", "unsplash_topics_photos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10027, 50),
    )
)

internal val Res.string.unsplash_users_collections: StringResource
  get() = CommonMainString0.unsplash_users_collections

private fun init_unsplash_users_collections(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unsplash_users_collections", "unsplash_users_collections",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10078, 58),
    )
)

internal val Res.string.unsplash_users_likes: StringResource
  get() = CommonMainString0.unsplash_users_likes

private fun init_unsplash_users_likes(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unsplash_users_likes", "unsplash_users_likes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10137, 56),
    )
)

internal val Res.string.unsplash_users_photos: StringResource
  get() = CommonMainString0.unsplash_users_photos

private fun init_unsplash_users_photos(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unsplash_users_photos", "unsplash_users_photos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10194, 49),
    )
)

internal val Res.string.unsupport_type: StringResource
  get() = CommonMainString0.unsupport_type

private fun init_unsupport_type(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:unsupport_type", "unsupport_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10244, 46),
    )
)

internal val Res.string.unsupported_type: StringResource
  get() = CommonMainString0.unsupported_type

private fun init_unsupported_type(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unsupported_type", "unsupported_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10291, 48),
    )
)

internal val Res.string.up_to_date: StringResource
  get() = CommonMainString0.up_to_date

private fun init_up_to_date(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:up_to_date", "up_to_date",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10340, 34),
    )
)

internal val Res.string.update: StringResource
  get() = CommonMainString0.update

private fun init_update(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:update", "update",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10375, 22),
    )
)

internal val Res.string.url_is_invalid: StringResource
  get() = CommonMainString0.url_is_invalid

private fun init_url_is_invalid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:url_is_invalid", "url_is_invalid",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10398, 42),
    )
)

internal val Res.string.user: StringResource
  get() = CommonMainString0.user

private fun init_user(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:user", "user",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10474, 20),
    )
)

internal val Res.string.userName: StringResource
  get() = CommonMainString0.userName

private fun init_userName(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:userName", "userName",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10441, 32),
    )
)

internal val Res.string.welcome: StringResource
  get() = CommonMainString0.welcome

private fun init_welcome(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome", "welcome",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10495, 27),
    )
)

internal val Res.string.zoom_effect: StringResource
  get() = CommonMainString0.zoom_effect

private fun init_zoom_effect(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:zoom_effect", "zoom_effect",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/showcaseapp.composeapp.generated.resources/values/strings.commonMain.cvr",
    10523, 35),
    )
)
